import React, { useState } from "react";
import "./LoginPage.css";
import { Link } from "react-router-dom";

const LoginPage = () => {
  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(true); // Set initial state to true for checked

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    localStorage.setItem("isChecked", !isChecked); // Save checkbox value to local storage
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isChecked) {
      if (loginId === "admin" && password === "stpaul") {
        window.location.href = "/adminmainlayout";
      } else {
        alert("Invalid login credentials");
      }
    } else {
      alert("Invalid login credentials.");
    }
  };

  return (
    <>
      <section className="container-fluid">
        <section class="loginwrapper">
          <div class="container">
            <div id="scene" class="scene" data-hover-only="false">
              <div class="circle" data-depth="1.2"></div>
              <div class="circle2" data-depth="1.2"></div>
              <div class="one" data-depth="0.9">
                <div class="content">
                  <span class="piece"></span>
                  <span class="piece"></span>
                  <span class="piece"></span>
                </div>
              </div>

              <div class="two" data-depth="0.60">
                <div class="content">
                  <span class="piece"></span>
                  <span class="piece"></span>
                  <span class="piece"></span>
                </div>
              </div>

              <div class="three" data-depth="0.40">
                <div class="content">
                  <span class="piece"></span>
                  <span class="piece"></span>
                  <span class="piece"></span>
                </div>
              </div>

              <Link
                class="p404"
                data-depth="0.50"
                to="#"
                onClick={() => window.history.back()}
              >
                <i className="fas fa-long-arrow-alt-left mt-2"></i> Go Back
              </Link>
            </div>

            <div class="text">
              <article>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="loginId" className="form-label">
                      Login ID:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="loginId"
                      value={loginId}
                      onChange={(e) => setLoginId(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Password:
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="d-flex justify-content-center">
                    <button type="submit" className="btn btn-primary">
                      Login
                    </button>
                  </div>
                </form>
              </article>
            </div>
          </div>
        </section>
      </section>
      <div className="main_check">
        <input
          type="checkbox"
          className="form-check-input"
          id="termsCheckbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
      </div>
    </>
  );
};

export default LoginPage;
