import React from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import Facilities from "../pages/Facilities";
import CarDetails from "../pages/CarDetails";
import Gallery from "../pages/Gallery";
import BlogDetails from "../pages/BlogDetails";
import NewsEvents from "../pages/NewsEvents";
import AcademicCalendar from "../pages/AcademicCalendar";
import NotFound from "../pages/NotFound";
import Contact from "../pages/Contact";
import MandatoryPublicDisclosure from "../pages/MandatoryPublicDisclosure";
import LoginPage from "../pages/LoginPage/LoginPage";
import AdminMainLayout from "../pages/Admin/AdminMainLayout";
import FeeStr from "../pages/FeeStr";
import Syllabus from "../pages/Syllabus";
import ExaminationNotice from "../pages/ExaminationNotice";
const Routers = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/adminmainlayout" element={<AdminMainLayout />} />
        <Route path="/about" element={<About />} />
        <Route path="/facilities" element={<Facilities />} />
        <Route path="/cars/:slug" element={<CarDetails />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/newsevents" element={<NewsEvents />} />
        <Route path="/blogs/:slug" element={<BlogDetails />} />
        <Route
          path="/MandatoryPublicDisclosure"
          element={<MandatoryPublicDisclosure />}
        />
        <Route path="/feestructure" element={<FeeStr />} />
        <Route path="/syllabus" element={<Syllabus />} />
        <Route path="/examinationnotice" element={<ExaminationNotice />} />
        <Route path="/academicalendar" element={<AcademicCalendar />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/loginpage" element={<LoginPage />} />
      </Routes>
    </>
  );
};

export default Routers;
