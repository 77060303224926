import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../styles/about-section.css";
import about from "../../assets/all-images/about.png";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/footer.css";
import logo1 from "../../assets/all-images/logo1.png";
import footerSchool from "../../assets/all-images/school_img/footerSchool.png";
import Common_URL from "../.././Config";
const quickLinks = [
  {
    path: "/about",
    display: "About",
  },

  {
    path: "#",
    display: "Privacy Policy",
  },

  {
    path: "/gallery",
    display: "Gallery",
  },
  {
    path: "/blogs",
    display: "Blog",
  },

  {
    path: "/contact",
    display: "Contact",
  },
];

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  const [contents, setContents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const facebookResponse = await axios.get(
          Common_URL + "&contenttype=facebook"
        );
        const twitterResponse = await axios.get(
          Common_URL + "&contenttype=twitter"
        );
        const linkedinResponse = await axios.get(
          Common_URL + "&contenttype=linkedin"
        );
        const youtubeResponse = await axios.get(
          Common_URL + "&contenttype=youtube"
        );
        const whatsappResponse = await axios.get(
          Common_URL + "&contenttype=whatsapp"
        );

        setContents([
          { platform: "Facebook", data: facebookResponse.data[0].contenturl },
          { platform: "Twitter", data: twitterResponse.data[0].contenturl },
          { platform: "Linkedin", data: linkedinResponse.data[0].contenturl },
          { platform: "Youtube", data: youtubeResponse.data[0].contenturl },
          { platform: "Whatsapp", data: whatsappResponse.data[0].contenturl },
        ]);
        console.log(linkedinResponse.data); // Check the structure and find the correct property
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };

    fetchData();
  }, []);

  const renderContents = () => {
    return contents.map((item, index) => (
      <li key={index}>
        <Link to={item.data} className="" target="_blank">
          <i className={`fab fa-${item.platform.toLowerCase()}`}></i>
        </Link>
      </li>
    ));
  };

  const [showModal, setShowModal] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = () => {
    // Handle form submission here
    console.log("Username: ", username);
    console.log("Password: ", password);
    // You can add your logic to handle form submission
  };
  return (
    <>
      <footer className="footer">
        <div class="solar_footer">
          <div class="container">
            <div class="solar_footer_shape">
              <div class="solar_footer_gradient"></div>
              <div class="row">
                <div class="col-lg-5 col-md-12">
                  <div class="solar_footer_ab">
                    <a href="index.html"></a>
                    <div className="text-center">
                      <img
                        src={logo1}
                        alt=""
                        className="img_fluid footer_logo"
                      />
                    </div>
                    <hr />
                    <div className="top_solar_btn mt-4">
                      <ul>{renderContents()}</ul>
                    </div>
                    <form class="center mt-4">
                      <input placeholder="email@example.com"></input>
                      <button type="button">Get In Touch !</button>
                    </form>
                    {/* <img src={footerSchool} alt="" className="footer_school" /> */}
                  </div>
                </div>
                <div class="col-lg-2 col-md-12">
                  <div class="solar_footer_contact">
                    <h3>useful links</h3>
                    <div class="solar_links">
                      <ListGroup>
                        {quickLinks.map((item, index) => (
                          <ListGroupItem
                            key={index}
                            className="mt-0 quick__link"
                          >
                            <i
                              class="fa fa-angle-double-right"
                              aria-hidden="true"
                            ></i>{" "}
                            <Link to={item.path}>{item.display}</Link>
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                    </div>
                  </div>
                </div>
                <div class="col-lg-5 col-md-12">
                  <div class="solar_footer_contact">
                    <div class="solar_information">
                      <ul>
                        <h3>contact us</h3>
                        <li>
                          <i class="fa fa-map-marker" aria-hidden="true"></i>{" "}
                          Sartha, Harnaut, Nalanda, Bihar-803110
                        </li>
                        <li>
                          <i class="fa fa-globe" aria-hidden="true"></i>{" "}
                          www.stpaulschoolharnaut.org
                        </li>
                        <li>
                          <i class="fa fa-envelope" aria-hidden="true"></i>{" "}
                          st.paulschoolharnaut@gmail.com
                        </li>
                        <li>
                          <i class="fa fa-phone" aria-hidden="true"></i> +91
                          9931151550, 9199848979
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="solar_copyright d-flex justify-content-between">
              <p>Copyright © 2024 St. Paul's All Rights Reserved</p>

              <p>
                Developed by{" "}
                <span className="text_warning">Broadsoft Solutions</span>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
